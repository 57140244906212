.scroller::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 10px;
}
.scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #3f3c4daa;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}
.scrller-container {
    overflow-y: auto;
    max-height: 100vh;
    padding: 10px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Grid {
    display: grid;
    grid-gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
}

@media screen and (min-width: 720px) {
    .Grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 10px;
    }
}

@media screen and (min-width: 1000px) {
    .Grid {
        grid-template-columns: repeat(3, minmax(auto, 1fr));
        grid-gap: 15px;
    }
}

.larg-grid {
    display: grid;
    grid-gap: 10px;
    padding-inline: 10px;
    grid-template-columns: repeat(1, minmax(auto, 1fr));
}

@media screen and (min-width: 720px) {
    .larg-grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 10px;
    }
}

/* @media screen and (min-width: 1200px) {
    .larg-grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 15px;
    }
} */


.icon{
    object-fit: cover;
}