/* :root {
    --table :#fff;
    --even: #b3d9d2;
    --header: #76bfb1;
    --header-text: #fff;
    --table-text :#2c3e50;
    --line:#014b3d38;

    --rounded: 15px;
  } */
:root {
    --table :#fff;
    --even: #f4f4f4;
    --header: #2c3e50;
    --header-text: #fff;
    --table-text :#2c3e50;
    --line:#014b3d38;
    --rounded: 8px;
  }
* { margin: 0; padding: 0;}
    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow: hidden;
    }
    .font-bold {font-family: "'BoutrosMBCDinkum Medium'";font-weight: bold;}
    .link{color: #2d303e;}
     
    .table-border{
        border-bottom: #67e8f9 1px solid;
     }
     /* Table */
    .owl-table {
        width: calc(100vw - 45px);
        font-size: 14px;
        user-select: none;
        background-color:var(--table) ;
        background-color: #2c3e50;
        font-family: "'BoutrosMBCDinkum Medium'";
        padding-bottom:200px;
        color:var(--table-text) ;
        text-align: right;
        font-weight: 300;
        display: grid;
        overflow: hidden;
        /* min-height: 100vh; */
    }

    
    .table-header{
        position: sticky;
        position: -webkit-sticky;
        grid-column: 1 / -1;
        top: -12px;
        font-weight: bold;
        background-color: var(--header);
        color: var(--header-text);
        font-family: "'BoutrosMBCDinkum Medium'";
        font-size: 14px;
        font-weight: 300;
        display: grid;
        padding-block: 5px;
        grid-template-columns: inherit;
    }
    .even {background-color: var(--even);}
    .dark .owl-table {background-color: #353848;color: #fff;}
    .dark .even {background-color: #fff;color: #353848;}
    .dark .header {background-color: #22212e;color: #fff;}
    
    .owl-table-td{
        /* border-bottom:#15474e 1px solid; */
        /* color: #2c3e50; */
        /* direction: ltr; */
        text-align: auto;
        padding:10px;
        direction: ltr;
        overflow: hidden;
    }
    .table-line{
        /* padding-left:0px;
        border: solid 1px var(--line); */
        padding: 1px;
        height: 100vh;
        background-color: var(--line);
        position: absolute;
        z-index: 1;
    }
    .table-line-split{
        /* padding-left:0px;
        border: solid 1px var(--line); */
        padding: 1px;
        /* height: 100vh; */
        bottom: 0;
        top: 0;
        position: absolute;
        z-index: 1;
    }
    
    #resizeble-target{left:0;}
    #resizeble-target:hover, #resizeble-target:active{cursor:w-resize;background-color: #37304a33;border: solid 1px var(--line);}
    
    #resizeble-target-split{right:-8px;width: 4px;background-color:#56799c;border-radius: 10px;top: 10px;bottom: 10px;}
    #resizeble-target-split:hover, #resizeble-target-split:active{cursor:w-resize;background-color:#88d9e3;}
    
    .base-table{
        background-color:var(--table) ;
        color:var(--table-text) ;
    }
    .round-table-1{
        background-color:var(--table) ;
        color:var(--table-text) ;
        border-radius: var(--rounded);
    }
    .round-table-1 .table-header{
        border-radius: var(--rounded) var(--rounded) 0 0;
        overflow: hidden;
    }

    .round-table{
        background-color:var(--table) ;
        color:var(--table-text) ;
        border-radius: var(--rounded) ;
    }
    .round-table .table-header{
        border-radius: var(--rounded) var(--rounded) 0 0;
        /* overflow: hidden; */
    }
    .round-table .even{
        /* box-shadow:inset -50px -40px 30px #0002; */
    }
/* Table */
 
/* Grid */
    .owl-grid {
        display: grid;
        width: calc(100vw - 45px);
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 13px;
    }
 
    @media (max-width: 600px) {
        .owl-grid {
            grid-template-columns: repeat(1, 1fr);
        }
    }
 
/* Grid */
    .close-popup{
    /* background-color: #0004; */
    inset: 0;
    z-index: 1;
    position: fixed;
    }

    
    .owl-popup{
        display: flex;
        flex-direction:column;
        gap: 12px;
        background-color: #ffffff;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        padding: 12px 24px;
        color: #f87171;
    }
    
    @media screen and (max-width: 768px) {
        .owl-popup{
            left: 10px;
            right: 10px;
            z-index: 999;
            position: fixed;
        }
      
    }
    @media screen and (min-width: 1280px) {
        .big-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
/* Grid */


.split-container {
    /* overflow: hidden; */
    height: 100%;
    grid-template-rows: auto 1fr auto 10px;
    color:var(--table-text) ;
    user-select: none;
    display: grid;
    gap: 10px;
    padding: 10px;
    /* margin-top: -100px; */
}


.details-container {
    font-size: 14px;
    font-weight: 300;
    background-color: #fff;
    color:var(--table-text) ;
    font-family: "'BoutrosMBCDinkum Medium'";
    border-radius: 0.7rem;
    position: relative;
 }

.details-container {
    grid-row: 1/4;
    grid-column: 2/2;
}

.details-inner-container {
    display: grid;
    gap: 10px;
    grid-template-rows: auto auto 1fr auto;
    height: calc(100% - 10px);
    padding: 10px;
}

/* .split-main-container {
    font-size: 14px;
    font-weight: 300;
    grid-template-rows: auto 1fr auto;
    font-family: "'BoutrosMBCDinkum Medium'";
    color:var(--table-text) ;
    padding: 10px;
    display: grid;
    gap: 10px;

} */

/* Cards  */
    .item-builder{
        font-family: "'BoutrosMBCDinkum Medium'";
        flex-direction: column;
        border-radius: 0.7rem;
        font-weight: bold;
        font-size: 13px;
        padding: 12px;
        display: flex;
        background-color: #ffffff;    
        box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
        /* overflow-x: auto; */
        min-height: 50px;
    }

    .obj{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 2px 10px;
        border-radius: 0.5rem;
        background-color: #ffffff;
        /* border: 1px solid #e0e0e0; */
        /* align-items: center; */
        /* box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16); */
        margin: 4px;
        cursor: grab;
        align-self: flex-start;
    }
/* Cards  */

.owl-search-options{
    display: grid;
    grid-template-columns: auto minmax(auto, 50%);
    gap: 10px;
}




/* Public */
.scroller::-webkit-scrollbar {height: 4px;width: 4px;}
.scroller::-webkit-scrollbar-track {border-radius: 100px;margin: 10px;}
.scroller::-webkit-scrollbar-thumb {background-color: #3f3c4d33; border-radius: 6px;}
.scroller:hover::-webkit-scrollbar-thumb {background-color: #3f3c4d77;}

.shadow-1 {box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);}
.shadow-gray{box-shadow: 0px 0px 6px rgba(107 ,114 ,128, 0.5);}
.shadow-cyan{box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);}
.shadow-red{box-shadow: 0px 0px 6px rgba(252, 165, 165,0.5);}
.shadow-purple{box-shadow: 0px 0px 6px rgba(168, 85 ,247,0.5);} 

.flex-grow {flex-grow: 1;}
.flex-1 {flex:1}
.row{display:flex; flex-direction: row;}
.row-center{display:flex; flex-direction: row;align-items: center;}
.col{display:flex; flex-direction: column;}
.col-center{display:flex; flex-direction: column;align-items: center;}
.wrap{display: flex; flex-wrap: wrap;}
.overflow-auto{overflow: auto;}
.overflow-hidden{overflow: hidden;}
.h-screen{height: 100vh;}
.w-screen{width: 100vw;}    
.w-full{width: 100%;}
.min-w-max{min-width: max-content;} 

.absolute{position: absolute;}
.fixed{position: fixed;}
.relative{position: relative;}
.sticky {position: -webkit-sticky;position: sticky;}
.inset-0{inset: 0;}
.right-0{right: 0;}
.left-0{left: 0;}
.top-0{top: 0;}
.bottom-0{bottom: 0;}   

.z-10{z-index: 1;}
.z-20{z-index: 2;}
.z-30{z-index: 3;}
.z-40{z-index: 4;}
.z-50{z-index: 5;}  

.items-center {align-items: center;}
.items-start {align-items: flex-start;}
.items-end {align-items: flex-end;}
.justify-center {justify-content: center;}
.justify-start {justify-content: flex-start;}
.justify-end {justify-content: flex-end;}
.justify-between {justify-content: space-between;}
.justify-around {justify-content: space-around;}
.justify-evenly {justify-content: space-evenly;}
.self-start {align-self: flex-start;}
.self-center {align-self: center;}  

.col-span-full{grid-column: 1 / -1;}    

.border-gray{border: solid 1px #D1D5DB ;}
.border-cayn{border: solid 1px rgb(32, 211, 238) }
.border-red{border: solid 1px rgb(252, 165, 165) }  

.text-center{text-align: center;}
.text-left{text-align: left;}
.text-right{text-align: right;} 

.owl-text-xs{font-size: 0.75rem;}
.owl-text-sm{font-size: 0.875rem;}
.owl-text-base{font-size: 1rem;}
.owl-text-lg{font-size: 1.125rem;}
.owl-text-xl{font-size: 1.25rem;}
.owl-text-2xl{font-size: 1.5rem;}
.owl-text-3xl{font-size: 2rem;} 

.pointer{cursor: pointer;}
.cursor-default{cursor: default;}
.cursor-cursor{cursor: w-resize;}   

.rounded-none	{border-radius: 0px;}
.rounded-sm	{border-radius: 0.125rem; }
.rounded	{border-radius: 0.25rem; }
.rounded-md	{border-radius: 0.375rem; }
.rounded-lg	{border-radius: 0.5rem; }
.rounded-xl	{border-radius: 0.75rem; }
.rounded-2xl	{border-radius: 1rem; }
.rounded-3xl	{border-radius: 1.5rem; }
.rounded-full	{border-radius: 9999px;}    

.text-left{text-align: left;}
.text-int-1{color: rgb(46, 205, 111);}
.text-string-1{color:rgb(255, 64, 129)} 