 /* transition */
.transition-0{
    transition:none;
  }
  .transition-1{
    transition: all 100ms ease-in-out;
  }
  .transition-2{
    transition: all 200ms ease-in-out;
  }
  .transition-3{
    transition: all 200ms ease-in-out;
  }
  .transition-4{
    transition: all 400ms ease-in-out;
  }
  .transition-5{
    transition: all 500ms ease-in-out;
  }
  /*  */
  /*  */
  .fad-opacity{
    animation: opacity-frames 200ms ease-in-out;
  }
  .fad-in{
    animation: fad-in-frames 300ms ease-in-out;
  }
  @keyframes fad-in-frames {
    0% {
      scale: 0;
    }
    100% {
      scale: 1;
    }
  }
  @keyframes fad-opacity-frames {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-svg-btn{
    animation: fade-svg-btn-frames 200ms ease-out ;
    margin-right: 0px;
    transform: rotate(0deg);
    z-index: 1;
    position: relative;
  }
  
  @keyframes fade-svg-btn-frames{
    0%{
      opacity:0.3;
      right: -100px;
      transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      transform: rotate(0deg);
    }
  }
  .fade-qantity-btn{
    animation: fade-qantity-btn-frames 200ms ease-out ;
    transform: rotate(0deg);
    z-index: 2;
    position: relative;

  }
  
  @keyframes fade-qantity-btn-frames{
    0%{
      opacity:0.3;
      right: -40px;
      transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      transform: rotate(0deg);
  }
}
  
  /*  */