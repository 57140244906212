  .lds-ripple {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      min-height: 50vh;
  }

  .lds-ripple div {
      opacity: 1;
      border-radius: 50%;
      position: absolute;
      border: 4px solid #22212e;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

   .dark .lds-ripple div {
      border: 4px solid #fff;
   }

  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      width: 0;
      height: 0;
      opacity: 1;
    }
 
    100% {
      width: 102px;
      height: 102px;
      opacity: 0;
    }
  }
  