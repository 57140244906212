.categories-card {
  padding: 10px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.20);
}


.categories-card img  {
  object-fit: contain;
  width: 150px;
  max-height: 100px;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}
 
.categories-card p {
    font-family: "'GE Dinkum Medium'";
}


.options-container{
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.options-container :nth-child(n){
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow:  0px 0px 10px rgba(0,0,0,0.2);
}
.options-container .active{
  /* border:  solid 2px  rgba(221, 54, 67,0.5); */
  background-color: rgba(221, 54, 67,1);
  color: #fff;
  /* box-shadow:  0px 0px 10px  rgba(221, 54, 67,0.5); */
}

.owl-label-conatiner{
  flex-direction: row;
  align-items: center;
  max-width: 160px;
  display:  flex ;
  flex-grow: 1;
  gap: 5px;
  padding: 9px;
  border-radius: 0.5rem;
  justify-content: space-between;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.owl-label{
  color: rgb(156 163 175);
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 300;
  margin-block: -20px;
  font-family: "'BoutrosMBCDinkum Medium'";
}
