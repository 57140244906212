@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-bg-color: #22212e;
}
body {
  margin-inline: auto;
  overflow: hidden;
  /* max-width: 720px; */
  padding-top: 10px;
  user-select: none;
  background-color: var(--main-bg-color);
  font-size: 14px;
}

.font-bold{
  font-family: "'GE Dinkum Medium'";
}
.font-light{
  font-family: "'BoutrosMBCDinkum Medium'";
}
 