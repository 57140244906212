/* :root {
    --table :#fff;
    --even: #b3d9d2;
    --header: #76bfb1;
    --header-text: #fff;
    --table-text :#2c3e50;
    --line:#014b3d38;

    --rounded: 15px;
  } */
:root {
    --table :#fff;
    --even: #f4f4f4;
    --header: #2c3e50;
    --header-text: #fff;
    --table-text :#2c3e50;
    --line:#014b3d38;
    --rounded: 8px;
  }
* { margin: 0; padding: 0;}
    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow: hidden;
    }
    .font-bold {font-family: "'BoutrosMBCDinkum Medium'";font-weight: bold;}
    .link{color: #2d303e;}
     
    .table-border{
        border-bottom: #67e8f9 1px solid;
     }
     /* Table */
    .owl-table {
        width: calc(100vw - 45px);
        font-size: 14px;
        -webkit-user-select: none;
                user-select: none;
        background-color:#fff;
        background-color:var(--table) ;
        background-color: #2c3e50;
        font-family: "'BoutrosMBCDinkum Medium'";
        padding-bottom:200px;
        color:#2c3e50;
        color:var(--table-text) ;
        text-align: right;
        font-weight: 300;
        display: grid;
        overflow: hidden;
        /* min-height: 100vh; */
    }

    
    .table-header{
        position: sticky;
        position: -webkit-sticky;
        grid-column: 1 / -1;
        top: -12px;
        font-weight: bold;
        background-color: #2c3e50;
        background-color: var(--header);
        color: #fff;
        color: var(--header-text);
        font-family: "'BoutrosMBCDinkum Medium'";
        font-size: 14px;
        font-weight: 300;
        display: grid;
        padding-block: 5px;
        grid-template-columns: inherit;
    }
    .even {background-color: #f4f4f4;background-color: var(--even);}
    .dark .owl-table {background-color: #353848;color: #fff;}
    .dark .even {background-color: #fff;color: #353848;}
    .dark .header {background-color: #22212e;color: #fff;}
    
    .owl-table-td{
        /* border-bottom:#15474e 1px solid; */
        /* color: #2c3e50; */
        /* direction: ltr; */
        text-align: auto;
        padding:10px;
        direction: ltr;
        overflow: hidden;
    }
    .table-line{
        /* padding-left:0px;
        border: solid 1px var(--line); */
        padding: 1px;
        height: 100vh;
        background-color: #014b3d38;
        background-color: var(--line);
        position: absolute;
        z-index: 1;
    }
    .table-line-split{
        /* padding-left:0px;
        border: solid 1px var(--line); */
        padding: 1px;
        /* height: 100vh; */
        bottom: 0;
        top: 0;
        position: absolute;
        z-index: 1;
    }
    
    #resizeble-target{left:0;}
    #resizeble-target:hover, #resizeble-target:active{cursor:w-resize;background-color: #37304a33;border: solid 1px #014b3d38;border: solid 1px var(--line);}
    
    #resizeble-target-split{right:-8px;width: 4px;background-color:#56799c;border-radius: 10px;top: 10px;bottom: 10px;}
    #resizeble-target-split:hover, #resizeble-target-split:active{cursor:w-resize;background-color:#88d9e3;}
    
    .base-table{
        background-color:#fff;
        background-color:var(--table) ;
        color:#2c3e50;
        color:var(--table-text) ;
    }
    .round-table-1{
        background-color:#fff;
        background-color:var(--table) ;
        color:#2c3e50;
        color:var(--table-text) ;
        border-radius: 8px;
        border-radius: var(--rounded);
    }
    .round-table-1 .table-header{
        border-radius: 8px 8px 0 0;
        border-radius: var(--rounded) var(--rounded) 0 0;
        overflow: hidden;
    }

    .round-table{
        background-color:#fff;
        background-color:var(--table) ;
        color:#2c3e50;
        color:var(--table-text) ;
        border-radius: 8px;
        border-radius: var(--rounded) ;
    }
    .round-table .table-header{
        border-radius: 8px 8px 0 0;
        border-radius: var(--rounded) var(--rounded) 0 0;
        /* overflow: hidden; */
    }
    .round-table .even{
        /* box-shadow:inset -50px -40px 30px #0002; */
    }
/* Table */
 
/* Grid */
    .owl-grid {
        display: grid;
        width: calc(100vw - 45px);
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-gap: 13px;
        gap: 13px;
    }
 
    @media (max-width: 600px) {
        .owl-grid {
            grid-template-columns: repeat(1, 1fr);
        }
    }
 
/* Grid */
    .close-popup{
    /* background-color: #0004; */
    inset: 0;
    z-index: 1;
    position: fixed;
    }

    
    .owl-popup{
        display: flex;
        flex-direction:column;
        grid-gap: 12px;
        gap: 12px;
        background-color: #ffffff;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        padding: 12px 24px;
        color: #f87171;
    }
    
    @media screen and (max-width: 768px) {
        .owl-popup{
            left: 10px;
            right: 10px;
            z-index: 999;
            position: fixed;
        }
      
    }
    @media screen and (min-width: 1280px) {
        .big-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
/* Grid */


.split-container {
    /* overflow: hidden; */
    height: 100%;
    grid-template-rows: auto 1fr auto 10px;
    color:#2c3e50;
    color:var(--table-text) ;
    -webkit-user-select: none;
            user-select: none;
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px;
    /* margin-top: -100px; */
}


.details-container {
    font-size: 14px;
    font-weight: 300;
    background-color: #fff;
    color:#2c3e50;
    color:var(--table-text) ;
    font-family: "'BoutrosMBCDinkum Medium'";
    border-radius: 0.7rem;
    position: relative;
 }

.details-container {
    grid-row: 1/4;
    grid-column: 2/2;
}

.details-inner-container {
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-rows: auto auto 1fr auto;
    height: calc(100% - 10px);
    padding: 10px;
}

/* .split-main-container {
    font-size: 14px;
    font-weight: 300;
    grid-template-rows: auto 1fr auto;
    font-family: "'BoutrosMBCDinkum Medium'";
    color:var(--table-text) ;
    padding: 10px;
    display: grid;
    gap: 10px;

} */

/* Cards  */
    .item-builder{
        font-family: "'BoutrosMBCDinkum Medium'";
        flex-direction: column;
        border-radius: 0.7rem;
        font-weight: bold;
        font-size: 13px;
        padding: 12px;
        display: flex;
        background-color: #ffffff;    
        box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
        /* overflow-x: auto; */
        min-height: 50px;
    }

    .obj{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 2px 10px;
        border-radius: 0.5rem;
        background-color: #ffffff;
        /* border: 1px solid #e0e0e0; */
        /* align-items: center; */
        /* box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16); */
        margin: 4px;
        cursor: grab;
        align-self: flex-start;
    }
/* Cards  */

.owl-search-options{
    display: grid;
    grid-template-columns: auto minmax(auto, 50%);
    grid-gap: 10px;
    gap: 10px;
}




/* Public */
.scroller::-webkit-scrollbar {height: 4px;width: 4px;}
.scroller::-webkit-scrollbar-track {border-radius: 100px;margin: 10px;}
.scroller::-webkit-scrollbar-thumb {background-color: #3f3c4d33; border-radius: 6px;}
.scroller:hover::-webkit-scrollbar-thumb {background-color: #3f3c4d77;}

.shadow-1 {box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);}
.shadow-gray{box-shadow: 0px 0px 6px rgba(107 ,114 ,128, 0.5);}
.shadow-cyan{box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);}
.shadow-red{box-shadow: 0px 0px 6px rgba(252, 165, 165,0.5);}
.shadow-purple{box-shadow: 0px 0px 6px rgba(168, 85 ,247,0.5);} 

.flex-grow {flex-grow: 1;}
.flex-1 {flex:1 1}
.row{display:flex; flex-direction: row;}
.row-center{display:flex; flex-direction: row;align-items: center;}
.col{display:flex; flex-direction: column;}
.col-center{display:flex; flex-direction: column;align-items: center;}
.wrap{display: flex; flex-wrap: wrap;}
.overflow-auto{overflow: auto;}
.overflow-hidden{overflow: hidden;}
.h-screen{height: 100vh;}
.w-screen{width: 100vw;}    
.w-full{width: 100%;}
.min-w-max{min-width: -webkit-max-content;min-width: max-content;} 

.absolute{position: absolute;}
.fixed{position: fixed;}
.relative{position: relative;}
.sticky {position: -webkit-sticky;position: sticky;}
.inset-0{inset: 0;}
.right-0{right: 0;}
.left-0{left: 0;}
.top-0{top: 0;}
.bottom-0{bottom: 0;}   

.z-10{z-index: 1;}
.z-20{z-index: 2;}
.z-30{z-index: 3;}
.z-40{z-index: 4;}
.z-50{z-index: 5;}  

.items-center {align-items: center;}
.items-start {align-items: flex-start;}
.items-end {align-items: flex-end;}
.justify-center {justify-content: center;}
.justify-start {justify-content: flex-start;}
.justify-end {justify-content: flex-end;}
.justify-between {justify-content: space-between;}
.justify-around {justify-content: space-around;}
.justify-evenly {justify-content: space-evenly;}
.self-start {align-self: flex-start;}
.self-center {align-self: center;}  

.col-span-full{grid-column: 1 / -1;}    

.border-gray{border: solid 1px #D1D5DB ;}
.border-cayn{border: solid 1px rgb(32, 211, 238) }
.border-red{border: solid 1px rgb(252, 165, 165) }  

.text-center{text-align: center;}
.text-left{text-align: left;}
.text-right{text-align: right;} 

.owl-text-xs{font-size: 0.75rem;}
.owl-text-sm{font-size: 0.875rem;}
.owl-text-base{font-size: 1rem;}
.owl-text-lg{font-size: 1.125rem;}
.owl-text-xl{font-size: 1.25rem;}
.owl-text-2xl{font-size: 1.5rem;}
.owl-text-3xl{font-size: 2rem;} 

.pointer{cursor: pointer;}
.cursor-default{cursor: default;}
.cursor-cursor{cursor: w-resize;}   

.rounded-none	{border-radius: 0px;}
.rounded-sm	{border-radius: 0.125rem; }
.rounded	{border-radius: 0.25rem; }
.rounded-md	{border-radius: 0.375rem; }
.rounded-lg	{border-radius: 0.5rem; }
.rounded-xl	{border-radius: 0.75rem; }
.rounded-2xl	{border-radius: 1rem; }
.rounded-3xl	{border-radius: 1.5rem; }
.rounded-full	{border-radius: 9999px;}    

.text-left{text-align: left;}
.text-int-1{color: rgb(46, 205, 111);}
.text-string-1{color:rgb(255, 64, 129)} 
    :root {
    --text :#2c3e50;
    --primary :#3498db;
    --secondary :#9b59b6;
    --success :#2ecc71;
    --info :#3498db;
    --warning :#f1c40f;
    --danger :#e74c3c;
    --light :#ecf0f1;
    --dark :#2c3e50;
    --body :#fff;
    --border :#bdc3c7;
    --background :#ecf0f1;
    --background-secondary :#bdc3c7;
    --background-primary :#3498db;
    --background-success :#2ecc71;
    --background-info :#3498db;
    --background-warning :#f1c40f;
    --background-danger :#e74c3c;
    --background-light :#ecf0f1;
    --background-dark :#2c3e50;
    --text-primary :#2c3e50;
    --text-secondary :#9b59b6;
    --text-success :#2ecc71;
    --text-info :#3498db;
    --text-warning :#f1c40f;
    --text-danger :#e74c3c;
    --text-light :#ecf0f1;
    --text-dark :#2c3e50;
    --border-primary :#3498db;
    --border-secondary :#9b59b6;
    --border-success :#2ecc71;
    --border-info :#3498db;
    --border-warning :#f1c40f;
    --border-danger :#e74c3c;
    --border-light :#ecf0f1;
    --border-dark :#2c3e50;
    --background-primary-hover :#2c3e50;
    --background-secondary-hover :#9b59b6;
    --background-success-hover :#2ecc71;
    --background-info-hover :#3498db;
    --background-warning-hover :#f1c40f;
    --background-danger-hover :#e74c3c;
    --background-light-hover :#ecf0f1;
    --background-dark-hover :#2c3e50;
    --text-primary-hover :#2c3e50;
    --text-secondary-hover :#9b59b6;
    --text-success-hover :#2ecc71;
    --text-info-hover :#3498db;
    --text-warning-hover :#f1c40f;
    --text-danger-hover :#e74c3c;
    --text-light-hover :#ecf0f1;
    --text-dark-hover :#2c3e50;
    --border-primary-hover :#3498db;
        
    --border-secondary-hover :#9b59b6;
    --border-success-hover :#2ecc71;
    --border-info-hover :#3498db;
    --border-warning-hover :#f1c40f;
    --border-danger-hover :#e74c3c;

        
    /* --table :#fff;
    --even: #b3d9d2;
    --header: #76bfb1;
    --header-text: #fff;
    --line:#014b3d38;
    --rounded: 15px; */
     } 


     .bg-primary  {background-color: #3498db;background-color: var(--primary);}
     .text-primary{color: #3498db;color: var(--primary);}

    /* *{ transition: all 10s ease-in-out;} */
     .font-bold {
         font-family: "'BoutrosMBCDinkum Medium'";
         font-weight: bold;
     }
    /* bg-gray-50 un-selectble  */
    .transition-0{ transition: all 0s ease-in-out;}
    .transition-1{ transition: all 0.1s ease-in-out;}
    .transition-2{ transition: all 0.2s ease-in-out;}
    .transition-3{ transition: all 0.3s ease-in-out;}
    .transition-4{ transition: all 0.4s ease-in-out;}
    /* .owl-none{} */
    
    .owl-item-details{
        display: grid;
        /* flex-wrap: wrap; */
        /* grid-template-columns: repeat(4, 0.1fr,0.4fr,0.2fr, 0.1fr); */
        grid-template-columns: 1fr 0.1fr 0.1fr 0.1fr;
        /* flex-direction: row; */
        align-items: center;
        /* justify-content: left; */
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
        /* box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.8); */
        /* border: dashed 1px #ccc ; */
        padding: 8px;
        border-radius: 5px;
        background-color: #f5f5f5;
    }
    /* .owl-item-details :nth-child(1){
     background-color: #2ecc71;        
    }
    .owl-item-details :nth-child(2){
        background-color: #3498db;        
    }
    .owl-item-details :nth-child(3){
     background-color: #2c3e50;        
    }
    .owl-item-details :nth-child(4){
     background-color: #f1c40f;        
    }
    .owl-item-details :nth-child(5){
     background-color: #9b59b6;        
    } */


    #plus {
        color: #40acdc;
        background-color: #fff;
        border-radius: 0.2rem;
        font-size: 21px;
        padding:2px 12px;
        cursor: pointer;
        /* box-shadow:  0px 0px 5px rgba(0, 0, 0, 0.1); */
        border:solid 1px rgba(32, 211, 238,0.3);

        /* box-shadow:0px 0px 5px rgba(32, 211, 238,0.3); */
    }

    #minus {
        color:rgb(248 113 113);
        background-color: #fff;
        border-radius: 0.2rem;
        font-size: 21px;
        padding:2px 14px;
        cursor: pointer;
        /* box-shadow:  0px 0px 5px rgba(0, 0, 0, 0.1); */
        border:solid 1px rgba(248 ,113 ,113 ,0.3);

        /* box-shadow:0px 0px 5px rgba(248 ,113 ,113 ,0.3); */

    }

    #delete{
        cursor: pointer;
        height: 25px;
    }
    #delete:hover{
        /* rotate and go up */
        -webkit-transform: rotate(-13deg) translateY(-3px);
                transform: rotate(-13deg) translateY(-3px);
        transition: all 0.1s ease-in-out;
     }

    #itemQuantity {
        background-color: #fff;
        border: none;
        font-size: 18px;
        width: 60px;
        text-align: center;
        transition: all 100ms ease-in ;
        outline: none;
        margin-inline: 5px;
        /* padding-block: 6px; */
        /* border:solid 1px #D1D5DB; */
        border-radius: 3px;
    }
    #itemQuantity:read-only {
        background-color: #fff;
        font-size: 20px;
        min-width: 25px;
        text-align: center;
        transition: all 100ms ease-in ;
        outline: none;
    }
   
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .owl-item-details:active #itemQuantity {
        /* background-color: #fff;
        width: 20px;
        text-align: center; */
        /* font-size: 25px; */
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
        /* font-size: 25px;
        opacity: 0.7; */
    }
    
    .owl-item-details .total{
        min-width: 80px;
        text-align: center;
    }

    .owl-count{
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
        position: absolute;
        font-size: 1rem;
        padding: 2px;
        min-width: 40px;
        left: 0;
        bottom: 0;
        border-top-right-radius:0.7rem;
        border-bottom-left-radius:0.7rem;
        transition: all 0.1s ease-in-out;
    }
    .owl-count-holder {transition: all 0.1s ease-in-out;grid-gap: 0;gap: 0;}
    .owl-count-holder:active {border-radius: 1.2rem; margin: -2px; word-spacing: 2px;grid-gap: 4px;gap: 4px;}
    .owl-count-holder:active .owl-count{font-size: 1.1rem;padding: 4px; border-top-right-radius: 1.4rem;border-bottom-left-radius:1.4rem;}

    /* .owl-active{background-color: #22d3ee !important;color: #fff !important; box-shadow: 0px 0px 4px rgba(32, 211, 238,0.2) !important;} */
    .owl-active{
        box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5)!important;
        color:rgb(21 94 117)!important;
    }
    .owl-un-active{
        box-shadow: 0px 0px 6px rgba(0,0,0,0.1)!important;
        color:#2c3e50!important;
        opacity: 0.5;
    }

    .owl-selected[aria-selected="true"]{
        background-color: #22d3ee !important;
        color: #fff !important;
        box-shadow: 0px 0px 4px rgba(32, 211, 238,0.2) !important;
    }
    
    .owl-focus{box-shadow: 0px 0px 4px rgba(32, 211, 238,0.6) !important;}
    .owl-warn{box-shadow: 0px 0px 4px rgba(253 ,224 ,71 ,0.6) !important;}
    .owl-error{box-shadow: 0px 0px 4px rgba(248 ,113 ,113 ,0.6) !important;}
    .owl-ok{box-shadow: 0px 0px 4px rgba(190 ,242 ,100 ,0.6) !important;}
    .owl-focus .state-target{
        /* background-color: rgba(32, 211, 238,0.4); */
        box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);
        color:rgb(21 94 117)
    }
        
    .owl-warn .state-target{
        /* background-color: rgba(253 ,224 ,71 ,0.4); */
        box-shadow: 0px 0px 6px rgba(253 ,224 ,71 ,0.5);
        color:rgba(253 ,224 ,71 ,0.7)
    }
        
    .owl-error .state-target{
        /* background-color: rgba(248 ,113 ,113 ,0.4); */
        box-shadow: 0px 0px 6px rgba(248 ,113 ,113 ,0.5);
        color:rgb(248 113 113)
    }
        
    .owl-ok .state-target{
        /* background-color: rgba(190 ,242 ,100 ,0.4); */
        box-shadow: 0px 0px 6px rgba(190 ,242 ,100 ,0.5);
        color:rgba(190 ,242 ,100 ,0.7)
    }
    .owl-none .state-target{height: 0;font-size: 0; }



    .owl-expand{
        max-height: 1800px;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }
    
    input::-webkit-input-placeholder {
        color: rgb(156 163 175);
        letter-spacing: 1px;
        font-size: 14px;
        z-index: 2;
        font-weight: 300;
        position: relative;
        background-color: #fff;
    }
    
    input::placeholder {
        color: rgb(156 163 175);
        letter-spacing: 1px;
        font-size: 14px;
        z-index: 2;
        font-weight: 300;
        position: relative;
        background-color: #fff;
    }
    
    .owl-query-conainer {
        display: grid;
        overflow: hidden;
        padding: 5px;
        grid-gap: 10px;
        gap: 10px;
        border-radius: 0.5rem;
        background-color: #fafafa;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
    
    .owl-query-section {
        padding: 16px 6px;
        flex-wrap: wrap;
        display: flex;
        grid-gap: 12px;
        gap: 12px;    
        align-items: center;
        border-radius: 0.5rem;
        /* border:  1px solid #ccc; */
        box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
        overflow: auto;
        background-color: #fff;
    }
    
    .owl-query-cleaner{
        flex-wrap:  wrap;
        display:  flex;
        grid-gap: 10px;
        gap: 10px;
        box-shadow: inset 0px 0px 8px #f8717155;
        border-radius: 0.5rem;
        margin-top: 10px;
        padding: 10px;
    }
    
    .owl-label-conatiner{
        flex-direction: row;
        align-items: center;
        max-width: 160px;
        display:  flex ;
        flex-grow: 1;
        grid-gap: 5px;
        gap: 5px;
        padding: 9px;
        border-radius: 0.5rem;
        background-color: #ffffff;
        justify-content: space-between;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
    
    .owl-label{
        color: rgb(156 163 175);
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: 300;
        margin-block: -20px;
        font-family: "'BoutrosMBCDinkum Medium'";
    }
    
    .owl-button{
        font-family: "'BoutrosMBCDinkum Medium'";
        background-color: #ffffff;
        color: rgb(248 113 113);
        border-radius: 0.5rem;
        padding: 4px 16px;
        font-size: 12px;
        border: none;
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 1px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
    
    .owl-button-active{
        background-color: rgb(248 113 113);
        color: #ffffff;
    }
    
    
    .owl-button-conatiner{
        position: relative;
    }

    
    .owl-close-space{
        position: absolute;
        margin-top: 10px;
        margin: -50px;
        padding: 50px;
        z-index: 999;
    }
    .hide {
    display: none;
    }
    
    .clear-icon{
        color: rgb(252, 165, 165);
        font-size: 26px;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: 300;
        left: 10px;
        z-index: 10;
        position: absolute;
        cursor: pointer;
    }
    
    .owl-input {
        font-family: "'BoutrosMBCDinkum Medium'";
        padding: 10px;
        outline: none;
        border: none;
        color: #2d303e;
        font-weight: bold;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    }
    
    .owl-input:focus{box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);}
    
    .owl-date{
        outline: none;
        border: none;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
        color: #2d303e;
    }
    
    .owl-options-buttons-container {
        /* color: #fff; */
        background-color: #f9f9f9;
        flex-direction: row;
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        padding:5px;
        border-radius: 0.5rem;
        box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.16);
        overflow-x: auto;
        max-width: 100%;
        transition: all 200ms ease-out
    }
    
    .owl-options-buttons-container::-webkit-scrollbar { height: 4px;width: 4px;}
    .owl-options-buttons-container::-webkit-scrollbar-track {background-color: #e4e4e4;border-radius: 100px;margin: 10px;}
    .owl-options-buttons-container::-webkit-scrollbar-track {background-color: #e4e4e4;}
    .owl-options-buttons-container::-webkit-scrollbar-thumb {background-color: #3f3c4d44;  border-radius: 6px;}
    /* .owl-options-buttons-container:hover::-webkit-scrollbar-thumb {background-color: #3f3c4d55;} */

    /*
    .owl-options-buttons-container:hover::-webkit-scrollbar-track {background-color: #e4e4e4;border-radius: 100px;margin: 10px;}
    .owl-options-buttons-container:hover::-webkit-scrollbar-thumb {border-radius: 6px;background-color: #3f3c4daa;box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);}
    .owl-options-buttons-container:hover::-webkit-scrollbar-track {background-color: #e4e4e4;} */
    
    .owl-button-options{
        /* color: rgb(6 182 212); */
        color: #2c3e50;
        color: var(--text);
        background-color: #fff;
        border-radius: 0.5rem;
        padding: 10px 16px;
        font-size: 12px;
        border: none;
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 1px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        min-width: -webkit-max-content;
        min-width: max-content;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
    }
    .owl-button-options#active{
        box-shadow: 0px 0px 5px rgba(32, 211, 238,1);
        /* background-color: rgba(6 ,182 ,212, 0.5); */
        color:rgb(21 94 117);
        font-size: 15px;
        padding: 0px 16px;
        /* color: #ffffff; */
    }

    .owl-options {
        /* color: #fff; */
        /* background-color: #2d303e; */
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
        border-radius: 0.5rem;
        padding: 10px 8px;
        outline: none;
        border: none;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
    }
    
    .owl-toggle-button{
        display: flex;
        margin-inline: 8px;
        align-items: center;
        flex-direction: row;
        margin-block:auto;
    }
    
    .owl-toggleed-bg{
        cursor: pointer;
        padding: 2px;
        display: flex;
        margin: 0 4px;
        border-radius: 50px;
        transition: all 0.2s ease-in-out;
        background-color: #fff;
        box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
        padding-left: 22px;
    }
    .owl-toggleed-bg span{
        padding: 10px;
        border-radius: 24px;
        background-color: #ffffff;
        box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
    }
 
    .owl-toggleed {
        padding-right: 22px;
        padding-left: 2px;
        background-color: #F87171;
    }
    
    .owl-radio-container{
        display: flex;
        margin-inline: 10px;
        box-shadow:  0px 0px 3px #f8717155;
        border-radius: 1rem;
        background-color: #fefefe;
        padding: 3px;
        
    }
    .owl-radio-container span {
        transition: all 0.1s ease-out;
        padding: 4px 14px;
        color: #2c3e5099;
        border:solid 1px #0000;
        min-width: 70px;
        text-align: center;
        letter-spacing: 1px;
        border-radius: 0.8rem;
        font-weight: 600;
        
    }
    
    .owl-radio-container .active {
        color: #fff;
        background-color: #f87171;
        /* border:solid 1px #3498dbaa; */
    }

    .radio-label{
        width: 50%;
        max-width: 220px;
    }

    .owl-radio-button{
        display: flex;
        margin-inline: 8px;
        align-items: center;
        flex-direction: row;
        margin-block:auto;
    }
    .owl-radio-dot{
        border-radius: 10px;
        font-size: 0;
        background-color: #ccc;
        border: #fff 5px solid;

        padding : 10px 0 2px 12px; 
        margin-inline: 4px;
    }
   
    /* Consts */
    
  
    
    /* .cut-words {
        max-height: 105px;
    } */
    /* Theme */
    .p-1 {padding:4px;}
    .p-2 {padding:8px;}
    .p-3 {padding:12px;}
    .p-4 {padding:16px;}
    .p-5 {padding:20px;}
    .p-6 {padding:24px;}
    .p-7 {padding:28px;}
    .p-8 {padding:32px;}
    .p-9 {padding:36px;}
    .p-10 {padding:40px;}
    .p-11 {padding:44px;}
    .p-12 {padding:48px;}
    .p-13 {padding:52px;}
    .p-14 {padding:56px;}
    .p-15 {padding:60px;}   

    .px-1 {padding-inline:4px;}
    .px-2 {padding-inline:8px;}
    .px-3 {padding-inline:12px;}
    .px-4 {padding-inline:16px;}
    .px-5 {padding-inline:20px;}
    .px-6 {padding-inline:24px;}
    .px-7 {padding-inline:28px;}
    .px-8 {padding-inline:32px;}
    .px-9 {padding-inline:36px;}
    .px-10 {padding-inline:40px;}
    .px-11 {padding-inline:44px;}
    .px-12 {padding-inline:48px;}
    .px-13 {padding-inline:52px;}
    .px-14 {padding-inline:56px;}
    .px-15 {padding-inline:60px;}
    .px-auto {padding-inline:auto;} 

    .m-1 {margin:4px;}
    .m-2 {margin:8px;}
    .m-3 {margin:12px;}
    .m-4 {margin:16px;}
    .m-5 {margin:20px;}
    .m-6 {margin:24px;}
    .m-7 {margin:28px;}
    .m-8 {margin:32px;}
    .m-9 {margin:36px;}
    .m-10 {margin:40px;}
    .m-11 {margin:44px;}
    .m-12 {margin:48px;}
    .m-13 {margin:52px;}
    .m-14 {margin:56px;}
    .m-15 {margin:60px;}    
    .m-auto {margin:auto;}  

    .mx-1 {margin-inline:4px;}
    .mx-2 {margin-inline:8px;}
    .mx-3 {margin-inline:12px;}
    .mx-4 {margin-inline:16px;}
    .mx-5 {margin-inline:20px;}
    .mx-6 {margin-inline:24px;}
    .mx-7 {margin-inline:28px;}
    .mx-8 {margin-inline:32px;}
    .mx-9 {margin-inline:36px;}
    .mx-10 {margin-inline:40px;}
    .mx-11 {margin-inline:44px;}
    .mx-12 {margin-inline:48px;}
    .mx-13 {margin-inline:52px;}
    .mx-14 {margin-inline:56px;}
    .mx-15 {margin-inline:60px;}
    .mx-auto {margin-inline:auto;}  

    .gap-1 {grid-gap:4px;gap:4px;}
    .gap-2 {grid-gap:8px;gap:8px;}
    .gap-3 {grid-gap:12px;gap:12px;}
    .gap-4 {grid-gap:16px;gap:16px;}
    .gap-5 {grid-gap:20px;gap:20px;}
    .gap-6 {grid-gap:24px;gap:24px;}
    .gap-7 {grid-gap:28px;gap:28px;}
    .gap-8 {grid-gap:32px;gap:32px;}
    .gap-9 {grid-gap:36px;gap:36px;}
    .gap-10 {grid-gap:40px;gap:40px;}
    .gap-11 {grid-gap:44px;gap:44px;}
    .gap-12 {grid-gap:48px;gap:48px;}
    .gap-13 {grid-gap:52px;gap:52px;}
    .gap-14 {grid-gap:56px;gap:56px;}
    .gap-15 {grid-gap:60px;gap:60px;}

    .text-cyan-50   {color: rgb(236 254 255);}
    .text-cyan-100  {color: rgb(207 250 254);}
    .text-cyan-200  {color: rgb(165 243 252);}
    .text-cyan-300  {color: rgb(103 232 249);}
    .text-cyan-400  {color: #22d3ee;}
    .text-cyan-500  {color: rgb(6 182 212);}
    .text-cyan-600  {color: rgb(8 145 178);}
    .text-cyan-700  {color: rgb(14 116 144);}
    .text-cyan-800  {color: rgb(21 94 117);}
    .text-cyan-900  {color: rgb(22 78 99);}
    .text-red-50    {color: rgb(254 242 242);}
    .text-red-100   {color: rgb(254 226 226);}
    .text-red-200   {color: rgb(254 202 202);}
    .text-red-300   {color: rgb(252 165 165);}
    .text-red-400   {color: rgb(248 113 113);}
    .text-red-500   {color: rgb(239 68 68);}
    .text-red-600   {color: rgb(220 38 38);}
    .text-red-700   {color: rgb(185 28 28);}
    .text-red-800   {color: rgb(153 27 27);}
    .text-red-900   {color: rgb(127 29 29);}
    .text-gray-50   {color: rgb(249 250 251);}
    .text-gray-100  {color: rgb(243 244 246);}
    .text-gray-200  {color: rgb(229 231 235);}
    .text-gray-300  {color: rgb(209 213 219);}
    .text-gray-400  {color: rgb(156 163 175);}
    .text-gray-500  {color: rgb(107 114 128);}
    .text-gray-600  {color: rgb(75 85 99);}
    .text-gray-700  {color: rgb(55 65 81);}
    .text-gray-800  {color: rgb(31 41 55);}
    .text-gray-900  {color: rgb(17 24 39);}
    .text-black     {color: rgb(0 0 0);}
    .text-white     {color: rgb(255 255 255);}
    .text-purple-50     {color: rgb(250 245 255);}
    .text-purple-100    {color: rgb(243 232 255);}
    .text-purple-200    {color: rgb(233 213 255);}
    .text-purple-300    {color: rgb(216 180 254);}
    .text-purple-400    {color: rgb(192 132 252);}
    .text-purple-500    {color: rgb(168 85 247);}
    .text-purple-600    {color: rgb(147 51 234);}
    .text-purple-700    {color: rgb(126 34 206);}
    .text-purple-800    {color: rgb(107 33 168);}
    .text-purple-900    {color: rgb(88 28 135);}    

    .text-black     {background-color :rgb(0 0 0);}
    .text-white     {background-color :rgb(255 255 255);}   

    .bg-cyan-50   {background-color: rgb(236 254 255);}
    .bg-cyan-100  {background-color: rgb(207 250 254);}
    .bg-cyan-200  {background-color: rgb(165 243 252);}
    .bg-cyan-300  {background-color: rgb(103 232 249);}
    .bg-cyan-400  {background-color: #22d3ee;}
    .bg-cyan-500  {background-color: rgb(6 182 212);}
    .bg-cyan-600  {background-color: rgb(8 145 178);}
    .bg-cyan-700  {background-color: rgb(14 116 144);}
    .bg-cyan-800  {background-color: rgb(21 94 117);}
    .bg-cyan-900  {background-color: rgb(22 78 99);}    

    .bg-red-50    {background-color: rgb(254 242 242);}
    .bg-red-100   {background-color: rgb(254 226 226);}
    .bg-red-200   {background-color: rgb(254 202 202);}
    .bg-red-300   {background-color: rgb(252 165 165);}
    .bg-red-400   {background-color: rgb(248 113 113);}
    .bg-red-500   {background-color: rgb(239 68 68);}
    .bg-red-600   {background-color: rgb(220 38 38);}
    .bg-red-700   {background-color: rgb(185 28 28);}
    .bg-red-800   {background-color: rgb(153 27 27);}
    .bg-red-900   {background-color: rgb(127 29 29);}   

    .bg-gray-50   {background-color: rgb(249 250 251);}
    .bg-gray-100  {background-color: rgb(243 244 246);}
    .bg-gray-200  {background-color: rgb(229 231 235);}
    .bg-gray-300  {background-color: rgb(209 213 219);}
    .bg-gray-400  {background-color: rgb(156 163 175);}
    .bg-gray-500  {background-color: rgb(107 114 128);}
    .bg-gray-600  {background-color: rgb(75 85 99);}
    .bg-gray-700  {background-color: rgb(55 65 81);}
    .bg-gray-800  {background-color: rgb(31 41 55);}
    .bg-gray-900  {background-color: rgb(17 24 39);}    

    .bg-black     {background-color :rgb(0 0 0);}
    .bg-white     {background-color :rgb(255 255 255);} 

    .rounded-none	{border-radius: 0px;}
    .rounded-sm	{border-radius: 0.125rem; }
    .rounded	{border-radius: 0.25rem; }
    .rounded-md	{border-radius: 0.375rem; }
    .rounded-lg	{border-radius: 0.5rem; }
    .rounded-xl	{border-radius: 0.75rem; }
    .rounded-2xl	{border-radius: 1rem; }
    .rounded-3xl	{border-radius: 1.5rem; }
    .rounded-full	{border-radius: 9999px;}    

    .bg-purple-50     {background-color: rgb(250 245 255);}
    .bg-purple-100    {background-color: rgb(243 232 255);}
    .bg-purple-200    {background-color: rgb(233 213 255);}
    .bg-purple-300    {background-color: rgb(216 180 254);}
    .bg-purple-400    {background-color: rgb(192 132 252);}
    .bg-purple-500    {background-color: rgb(168 85 247);}
    .bg-purple-600    {background-color: rgb(147 51 234);}
    .bg-purple-700    {background-color: rgb(126 34 206);}
    .bg-purple-800    {background-color: rgb(107 33 168);}
    .bg-purple-900    {background-color: rgb(88 28 135);}   
@tailwind base;
@tailwind components;
@tailwind utilities;

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-bg-color: #22212e;
}
body {
  margin-inline: auto;
  overflow: hidden;
  /* max-width: 720px; */
  padding-top: 10px;
  -webkit-user-select: none;
          user-select: none;
  background-color: #22212e;
  background-color: var(--main-bg-color);
  font-size: 14px;
}

.font-bold{
  font-family: "'GE Dinkum Medium'";
}
.font-light{
  font-family: "'BoutrosMBCDinkum Medium'";
}
 
.item-card {
  padding: 15px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.30);
}

.item-card-image-container {
  display: flex ;
  flex-direction: column;
}

.item-card img  {
  width: 140px;
  object-fit: cover;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}

.item-card .resever  {
  height: 100px;
  object-fit: cover;
  border-radius: 20px ;
}

.item-card p {
    font-family: "'GE Dinkum Medium'";
}

/*TamplateTwo  */
.item-card-2 {
  padding: 10px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.30);
}

.item-card-2-image-container {
  display: flex ;
  flex-direction: column;
}

.item-card-2 img  {
  max-width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  margin-bottom: 0%;
  margin-inline:auto;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}
.chosen-t2 img  {
  max-width: 80%;
  margin-top: 20%;
  border-radius: 50%;
}

.amount-label {
  visibility: hidden;
}
.chosen-t2 .amount-label {
  visibility: visible;
}
.price{
  transition: all 300ms ease-in-out;
}
.chosen-t2 .price {
  top: -30px;
}

/* font-family: "'BoutrosMBCDinkum Medium'"; */
.item-card-2 p {
    font-family: "'GE Dinkum Medium'";
}
.categories-card {
  padding: 10px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.20);
}


.categories-card img  {
  object-fit: contain;
  width: 150px;
  max-height: 100px;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}
 
.categories-card p {
    font-family: "'GE Dinkum Medium'";
}


.options-container{
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}
.options-container :nth-child(n){
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow:  0px 0px 10px rgba(0,0,0,0.2);
}
.options-container .active{
  /* border:  solid 2px  rgba(221, 54, 67,0.5); */
  background-color: rgba(221, 54, 67,1);
  color: #fff;
  /* box-shadow:  0px 0px 10px  rgba(221, 54, 67,0.5); */
}

.owl-label-conatiner{
  flex-direction: row;
  align-items: center;
  max-width: 160px;
  display:  flex ;
  flex-grow: 1;
  grid-gap: 5px;
  gap: 5px;
  padding: 9px;
  border-radius: 0.5rem;
  justify-content: space-between;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.owl-label{
  color: rgb(156 163 175);
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 300;
  margin-block: -20px;
  font-family: "'BoutrosMBCDinkum Medium'";
}

.scroller::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 10px;
}
.scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #3f3c4daa;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}
.scrller-container {
    overflow-y: auto;
    max-height: 100vh;
    padding: 10px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.Grid {
    display: grid;
    grid-gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
}

@media screen and (min-width: 720px) {
    .Grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 10px;
    }
}

@media screen and (min-width: 1000px) {
    .Grid {
        grid-template-columns: repeat(3, minmax(auto, 1fr));
        grid-gap: 15px;
    }
}

.larg-grid {
    display: grid;
    grid-gap: 10px;
    padding-inline: 10px;
    grid-template-columns: repeat(1, minmax(auto, 1fr));
}

@media screen and (min-width: 720px) {
    .larg-grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 10px;
    }
}

/* @media screen and (min-width: 1200px) {
    .larg-grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 15px;
    }
} */


.icon{
    object-fit: cover;
}
 /* transition */
.transition-0{
    transition:none;
  }
  .transition-1{
    transition: all 100ms ease-in-out;
  }
  .transition-2{
    transition: all 200ms ease-in-out;
  }
  .transition-3{
    transition: all 200ms ease-in-out;
  }
  .transition-4{
    transition: all 400ms ease-in-out;
  }
  .transition-5{
    transition: all 500ms ease-in-out;
  }
  /*  */
  /*  */
  .fad-opacity{
    -webkit-animation: opacity-frames 200ms ease-in-out;
            animation: opacity-frames 200ms ease-in-out;
  }
  .fad-in{
    -webkit-animation: fad-in-frames 300ms ease-in-out;
            animation: fad-in-frames 300ms ease-in-out;
  }
  @-webkit-keyframes fad-in-frames {
    0% {
      scale: 0;
    }
    100% {
      scale: 1;
    }
  }
  @keyframes fad-in-frames {
    0% {
      scale: 0;
    }
    100% {
      scale: 1;
    }
  }
  @-webkit-keyframes fad-opacity-frames {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fad-opacity-frames {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-svg-btn{
    -webkit-animation: fade-svg-btn-frames 200ms ease-out ;
            animation: fade-svg-btn-frames 200ms ease-out ;
    margin-right: 0px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    z-index: 1;
    position: relative;
  }
  
  @-webkit-keyframes fade-svg-btn-frames{
    0%{
      opacity:0.3;
      right: -100px;
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  
  @keyframes fade-svg-btn-frames{
    0%{
      opacity:0.3;
      right: -100px;
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  .fade-qantity-btn{
    -webkit-animation: fade-qantity-btn-frames 200ms ease-out ;
            animation: fade-qantity-btn-frames 200ms ease-out ;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    z-index: 2;
    position: relative;

  }
  
  @-webkit-keyframes fade-qantity-btn-frames{
    0%{
      opacity:0.3;
      right: -40px;
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
}
  
  @keyframes fade-qantity-btn-frames{
    0%{
      opacity:0.3;
      right: -40px;
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
}
  
  /*  */
.row-center { display: flex; flex-direction: row; align-items: center;}
.col-center {display: flex;flex-direction: column; align-items: center;}
.col {display: flex;flex-direction: column;}
.row { display: flex; flex-direction: row;}
.wrap { display: flex; flex-wrap:wrap;}


.overflow-hidden { overflow: hidden;}
.overflow-auto { overflow: auto;}
.overflow-x-auto { overflow-x:  auto;}
.overflow-x-hidden { overflow-x:  hidden;}
.overflow-y-auto { overflow-y:  auto;}
.overflow-y-hidden { overflow-y:  hidden;}
.hidden{ display: none;}
.flex-grow{flex-grow: 1;}
.flex-1{flex: 1 1;}

.shdow-0{box-shadow: 0px 0px 10px rgba(0,0,0,0.20); }
.shdow-1{box-shadow: 0px 0px 10px rgba(0,0,0,0.30); }
.shdow-2{box-shadow: 0px 0px 10px rgba(0,0,0,0.40); }
.shdow-3{box-shadow: 0px 0px 10px rgba(0,0,0,0.50); }
.shdow-4{box-shadow: 0px 0px 10px rgba(0,0,0,0.60); }

.rounded-1 {border-radius: 5px;}
.rounded-2 {border-radius: 10px;}
.rounded-3 {border-radius: 15px;}
.rounded-4 {border-radius: 20px;}
.rounded-5 {border-radius: 25px;}
.rounded-full{border-radius: 50%;}

.fixed {position:fixed;}
.sticky {position:-webkit-sticky;position:sticky;}
.absolute {position:absolute;}
.relative {position: relative;}

.justify-around{justify-content: space-around;}
.justify-between{justify-content: space-between;}
.justify-center{justify-content: center;}

.items-center{align-items: center;}
.items-start{align-items: flex-start;}
.items-end{align-items: flex-end;}

.text-center{text-align: center;}
.text-left{text-align:left ;}
.text-start{text-align:start ;}

.p-1{padding: 4px;}
.p-2{padding: 6px;}
.p-3{padding: 8px;}
.p-4{padding: 10px;}
.p-5{padding: 12px;}
.p-6{padding: 14px;}
.p-7{padding: 16px;}
.p-8{padding: 18px;}
.p-9{padding: 24px;}
.p-10{padding: 22px;}

.py-1{padding-block: 4px;}
.py-2{padding-block: 6px;}
.py-3{padding-block: 8px;}
.py-4{padding-block: 10px;}
.py-5{padding-block: 12px;}
.py-6{padding-block: 14px;}
.py-7{padding-block: 16px;}
.py-8{padding-block: 18px;}
.py-9{padding-block: 24px;}
.py-10{padding-block: 28px;}

.px-1{padding-inline: 4px;}
.px-2{padding-inline: 6px;}
.px-3{padding-inline: 8px;}
.px-4{padding-inline: 10px;}
.px-5{padding-inline: 12px;}
.px-6{padding-inline: 14px;}
.px-7{padding-inline: 16px;}
.px-8{padding-inline: 18px;}
.px-9{padding-inline: 24px;}
.px-10{padding-inline: 28px;}

.m-1{margin: 4px;}
.m-2{margin: 6px;}
.m-3{margin: 8px;}
.m-4{margin: 10px;}
.m-5{margin: 12px;}
.m-6{margin: 14px;}
.m-7{margin: 16px;}
.m-8{margin: 18px;}
.m-9{margin: 24px;}
.m-10{margin: 28px;}
.m-auto{margin:auto;}

.my-1{margin-block: 4px ;}
.my-2{margin-block: 6px ;}
.my-3{margin-block: 8px ;}
.my-4{margin-block: 10px ;}
.my-5{margin-block: 12px ;}
.my-6{margin-block: 14px ;}
.my-7{margin-block: 16px ;}
.my-8{margin-block: 18px ;}
.my-9{margin-block: 24px ;}
.my-10{margin-block: 28px ;}
.my-auto{margin-block:auto;}

.mx-1{margin-inline:4px;}
.mx-2{margin-inline:6px;}
.mx-3{margin-inline:8px;}
.mx-4{margin-inline:10px;}
.mx-5{margin-inline:12px;}
.mx-6{margin-inline:14px;}
.mx-7{margin-inline:16px;}
.mx-8{margin-inline:18px;}
.mx-9{margin-inline:24px;}
.mx-10{margin-inline:28px;}
.mx-auto{margin-inline:auto;}
 
.gap-1{grid-gap: 4px;gap: 4px;}
.gap-2{grid-gap: 6px;gap: 6px;}
.gap-3{grid-gap: 8px;gap: 8px;}
.gap-4{grid-gap: 10px;gap: 10px;}
.gap-5{grid-gap: 12px;gap: 12px;}
.gap-6{grid-gap: 14px;gap: 14px;}
.gap-7{grid-gap: 16px;gap: 16px;}
.gap-8{grid-gap: 18px;gap: 18px;}
.gap-9{grid-gap: 24px;gap: 24px;}
.gap-10{grid-gap: 28px;gap: 28px;}

.text-xs{font-size: 8px;}
.text-sm{font-size: 10px;}
.text-md{font-size: 12px;}
.text-lg{font-size: 14px;}
.text-xl{font-size: 16px;}
.text-2xl{font-size: 18px;}
.text-3xl{font-size: 20px;}
.text-4xl{font-size: 24px;}
.text-5xl{font-size: 28px;}
.text-6xl{font-size: 34px;}

.z-1{z-index: 1;}
.z-2{z-index: 2;}
.z-3{z-index: 3;}
.z-4{z-index: 4;}
.z-5{z-index: 5;}
.z-6{z-index: 6;}
.z-7{z-index: 7;}
.z-8{z-index: 8;}
.z-9{z-index: 9;}
.z-10{z-index: 10;}

.backdrop-blur-none{-webkit-backdrop-filter: blur(0);backdrop-filter: blur(0);}
.backdrop-blur-sm{-webkit-backdrop-filter: blur(4px);backdrop-filter: blur(4px);}
.backdrop-blur-md{-webkit-backdrop-filter: blur(8px);backdrop-filter: blur(8px);}
.backdrop-blur-lg{-webkit-backdrop-filter: blur(16px);backdrop-filter: blur(16px);}
.backdrop-blur-xl{-webkit-backdrop-filter: blur(24px);backdrop-filter: blur(24px);}
.backdrop-blur-2xl{-webkit-backdrop-filter: blur(40px);backdrop-filter: blur(40px);}
.backdrop-blur-3xl{-webkit-backdrop-filter: blur(64px);backdrop-filter: blur(64px);}

.inset-0{inset: 0;}
.inset-1{inset: 4px;}
.inset-2{inset: 6px;}
.inset-3{inset: 8px;}
.inset-4{inset: 10px;}
.inset-5{inset: 12px;}
.inset-6{inset: 14px;}
.inset-7{inset: 16px;}
.inset-8{inset: 18px;}
.inset-9{inset: 24px;}
.inset-10{inset: 28px;}

.max-w-xs{
    max-width: 150px;
}
.max-w-sm{
    max-width: 250px;
}
.max-w-md{
    max-width: 420px;
}
.max-w-lg{
    max-width: 768px;
}
.max-w-xl{
    max-width: 1024px;
}
.max-w-2xl{
    max-width: 1280px;
}
.max-w-3xl{
    max-width: 1536px;
}
.max-w-4xl{
    max-width: 1920px;
}
.h-full{
    height:90vh
}


input {border: none; outline-width: 0px;  }
.pointer {cursor: pointer; }

.top-0 {top: 0; }
.left-0 {left: 0; }
.right-0 {right: 0; }
.bottom-0 {bottom: 0; }

.top-1 {top: 45px; }
.left-1 {left: 45px; }
.right-1 {right: 45px; }
.bottom-1 {bottom: 45px; }

.top-2 {top: 90px; }
.left-2 {left: 90px; }
.right-2 {right: 90px; }
.bottom-2 {bottom: 90px; }

.col-span-full{grid-column: 1 / -1;} 

.background {background-color: #f5f5f5;}
.dark .background {background-color: #1f1d2b;}


.item-icon-bg {fill: #efeff0;}
.dark .item-icon-bg {fill: #5c5e69;}


.item-defult-icon-bg {background-color: #f4f4f5;}
.dark .item-defult-icon-bg {background-color:  #3c3f4c;}


.carbonic-card{background-color: #FFFFFF;} 
.dark .carbonic-card{background-color: #2d303e;} 

.carbonic-card-1{background-color: #FBFBFB;} 
.dark .carbonic-card-1{background-color: #353848;} 

.carbonic-card-2{background-color: #FFFFFF;} 
.dark .carbonic-card-2{background-color: #393c4a;} 

.carbonic-card-3{background-color: #FBFBFB;} 
.dark .carbonic-card-3{background-color: #292a39;} 

.carbonic-card-4{background-color: #F0F0F0;} 
.dark .carbonic-card-4{background-color: #393c4a;} 

.carbonic-card-5{background-color: #f6f6f6;} 
.dark .carbonic-card-5{background-color: #3b3e4c;} 

.carbonic-card-6{background-color: #eaeaeb;} 
.dark .carbonic-card-6{background-color: #eaeaeb;} 

.carbonic-card-7{background-color: #fbfbfb;} 
.dark .carbonic-card-7{background-color: #3c3f4c;} 

.carbonic-card-8{background-color: #f5f5f5;} 
.dark .carbonic-card-8{background-color: #22212e;} 

.blur-card{background-color: #f5f5f555;} 
.dark .blur-card{background-color: #22212e55;} 

.blur-card-1{background-color: #63cfc955;} 
.dark .blur-card-1{background-color: #63cfc955;} 


.white-card{background-color: #FFFFFF;}

 
.search-bg {background-color: #ffffff;}
.dark .search-bg {background-color: #3c3f4c;}

.account-check-bg {background-color: #ffffff;}
.dark .account-check-bg {background-color: #3c3f4c;}


.carbonic {color: #1f1d2b;}
.dark .carbonic {color: #ffffff;}


.carbonic-1 {color: #c3c3c6;}
.dark .carbonic-1 {color: #6c6e78;}


.carbonic-2 {color: #c4c4c7;}
.dark .carbonic-2 {color: #9e9fa6;}


.carbonic-3 {/* TextGray */color: #c4c4c7;}
.dark .carbonic-3 {color: #767982;}


.carbonic-4 {color: #bebec1;}
.dark .carbonic-4 {color: #767982;}


.check-bg {background-color: #f3f3f1;}
.dark .check-bg {background-color: #4c4e5a;}

.compo-bg {background:rgba(196, 196, 199,0.85);}
.dark .compo-bg {background:rgba(31, 29, 43,0.85);}




.bg-logout {background-color: #f4e2e3;}

.dark .bg-logout {background-color: #36232f;}


.menu-label {background-color: #fcfcfc;}
.dark .menu-label {background-color: #2d2c39;}

.red-card {background-color: #dd3643;}
.green-bg {background-color: #56e181;}
.check-bg {background-color: #e9e9eb;}
.white-card {background-color: #ffffff;}
.dark .check-bg {background-color: #494c5a;}




.orange-card {background-color: #f48114;}
.orange-card-1 {background-color: #ffa44e;}
.orange-card-2 {background-color: #f9c089;}
.dark .orange-card-1 {background-color: #905929;}

.dark .orange-card-2 {background-color: #ffb938;}

.cyan-card {background-color: #63cfc9;}

.green-card {background-color: #56e181;}

.orange-text {color: #f48114;}
.orange-text-2 {color: #ffa44e;}
.orange-text-3 {color: #f9c089;}
.dark .orange-text-3 {color: #905929;}

.dark .orange-text-4 {color: #ffb938;}

.cyan-text {color: #63cfc9;}
.green-text {color: #56e181;}
.red-text {color: #dd3643;}
.white-text {color: #fff;}

.red-text-1 {color: #ec4245;}

.dark .red-text-1 {color: #ffffff;}


/* .green-text {color: #1bd69a;} */
.menu-phone {color: #6b6d78;}
/* MenuPhone */
.dark .menu-phone {color: #c4c4c7;}


/* .shadow-1 {box-shadow: 0px 0px 10px rgba(60, 63, 76, 0.15);} */
.shadow-1 {box-shadow: 0px 0px 10px rgb(45, 48, 62,0.15);}


/* .dark .shadow-1 {box-shadow: unset;} */


/* -///////////////////- */
/* -///////////////////- */
/* tincture */
.tint {fill: #c4c4c7;}

.dark .tint {fill: #6c6f78;}


.tint-stroke {stroke: #c4c4c7;}
.dark .tint-stroke {stroke: #6c6f78;}


.tint-1 {fill: #c3c3c6;}
.dark .tint-1 {fill: #72747f;}


.tint-2 {fill: #b8b7bc;}
.dark .tint-2 {fill: #72747f;}


.tint-item {fill: #d5d6d9;}
.dark .tint-item {fill: #6d6f78;}


.tint-menu {fill: #b8b7bc;}
.dark .tint-menu {fill: #96969c;}


.tint-tab {fill: #bebec1;}
.dark .tint-tab {fill: #62606b;}

.active .tint-tab {fill: #dd3643;}

.tint-main-tab {fill: #fff;}
.dark .tint-main-tab {fill: #8b8a91;}

.active .tint-main-tab {fill: #fff;}

.tint-squer {border: solid 2px #d9d9da;}
.dark .tint-squer {border: solid 2px #767982;} 



/* Accounts */
.accounts-business-svg {fill: #efeff0;}
.dark .accounts-business-svg {fill: #5c5e69;}


/*  */

/* Accounts */

/* -///////////////////- */
/* -///////////////////- */

.border-left {border-left: solid 1px #ebebeb;}

.dark .border-left {border-left: solid 1px #1f1d2b;}


.border-top {border-top: solid 2px #ebebeb;}

.dark .border-top {border-top: solid 2px #1f1d2b;}

.border-bottom {border-bottom: solid 2px #ebebeb;}

.dark .border-bottom {border-bottom: solid 2px #1f1d2b;}


.menu-profile-border {border: solid 2px #dcdcde;}
/* MenuImageBorder */
.dark .menu-profile-border {border: solid 2px #3a3a46;}


/* -///////////////////- */
/* -///////////////////- */

.backButton {background-color: #c6c6ca;padding: 6px 10px;}

.dark .backButton {background-color: #8f8e95;padding: 6px 10px;}


/* -///////////////////- */

/*  *** *** *** ***  */
/*  *** *** *** ***  */
.un-active-mode {background: linear-gradient(90deg, #bebec1, #bebec1);}
.dark .un-active-mode {background: linear-gradient(90deg, #65636d, #65636d);}


.acitve-mode {background: linear-gradient(90deg, #d0394f, #e83439);color: #fff;}
.dark .acitve-mode {background: linear-gradient(90deg, #d0394f, #e83439);color: #fff;}

.acitve-mode-shadow {box-shadow: 0px 0px 25px #e7353c;/* rgba(231, 53, 60, 1); */}

.mode-svg {fill: #fff0;}

.acitve-mode .mode-svg {fill: #fff;}

.item-quantity {left: -10px;fill: #fff;display: flex;padding: 0 4px;position: absolute;border-radius: 25px;flex-direction: row;align-items: center;background-color: #f48114;border: solid 2px #d17117;
}

/*  *** *** *** ***  */
 
.carbonic-card-svg {fill: #ffffff;}
.dark .carbonic-card-svg {fill: #2d303e;}


.invoice-top {background-color: #f5f5f5;}
.dark .invoice-top {background-color: #3d404e;}


.invoice-shape {background-color: #e6e6e6;}

.dark .invoice-shape {background-color: #393c4a;}

.invoice-shape-svg {fill: #e6e6e6;}

.dark .invoice-shape-svg {fill: #393c4a;}


.invoice-border-svg {fill: #ecebe8;}

.dark .invoice-border-svg {fill: #494c5a;}


.invoice-number-svg {fill: #c4c4c7;}
.dark .invoice-number-svg {fill: #4d505c;}


.invoice-bg-date {background-color: #fbfbfb;border-top: solid 1px #ebebeb;}
.dark .invoice-bg-date {background-color: #292a39;border-top: solid 1px #393d4d;}


.invoice-bg-date-2 {background-color: #fbfbfb;border: solid 1px #ededee;}
.dark .invoice-bg-date-2 {background-color: #383b48;border: solid 1px #4c4f5b;}


.invoice-quantity {background-color: #c4c4c7;border: solid 2px #b9b9bb;fill: #fff;}

.dark .invoice-quantity {background-color: #484a57;border: solid 2px #3f414e;fill: #767982;}


.invoice-bg-header {background-color: #faf9f7;border-bottom: solid 1px #e7e6de;}
.dark .invoice-bg-header {background-color: #393c4a;border-bottom: solid 1px #4c4f5b;}


.invoice-bg-items {background-color: #ffffff;}
.dark .invoice-bg-items {background-color: #313442;}


.tint-price-svg {fill: #c4c4c7;}

.dark .tint-price-svg {fill: #6c6e78;}


/* Invoices */

.tab-container-bg {background-color: #f5f5f550;}
.dark .tab-container-bg {background-color: #1f1d2b50;}


/* Svgs */
.text-input{border-radius : 14px;padding: 10px;fill:#c4c4c7 ;background-color: #ffffff;border : solid 2px #efeff0;}
.dark .text-input{fill:#767982 ;background-color: #3c3f4c;border : solid 2px #3c3f4c;}


.text-input input{background-color: #ffffff;}
.dark .text-input input{background-color: #3c3f4c;}


.dark .icon-card svg{fill: #767982;stroke: #494c5a;}

.light .icon-card svg{fill: #ecebe8;stroke: #bebec1;}

.select-icon{fill: #1f1d2b;}
.dark .select-icon{fill: #9e9fa6;}

.border-none{border: none;}

.svg-icon-with-bg{padding:10px;width: 40px;fill: #bebec1;border-radius: 10px;background-color: #FFFFFF;box-shadow: 0px 0px 10px rgb(45, 48, 62,0.15);}
.dark .svg-icon-with-bg{background-color: #2d303e;}


.svg-icon{fill: #bebec1; height: 36px;}
.svg-icon .border{fill: #ecebe8;}
.container-checked .svg-icon{fill: #f1bac4;}
.container-checked .svg-icon .border{fill: #e87f90;}
.container-checked .carbonic-3{color: #f0b9c5;}




.not-fount .svg-icon{fill: #DCDCDD;background-color:#f0f1f1 ;height: 36px;border-radius: 10px;}
.not-fount .svg-icon .border{fill: #f0f1f1;}

.not-fount p{color: #D9DDDD;}

/* Svgs */

.read-only-bg{border-radius : 14px;padding: 10px;fill:#c4c4c7 ;background-color: #e8e8e9;border : solid 2px #efeff0;}
.read-only-bg input{background-color: #e8e8e9;}

  .lds-ripple {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      min-height: 50vh;
  }

  .lds-ripple div {
      opacity: 1;
      border-radius: 50%;
      position: absolute;
      border: 4px solid #22212e;
      -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
              animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

   .dark .lds-ripple div {
      border: 4px solid #fff;
   }

  .lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  @-webkit-keyframes lds-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      width: 0;
      height: 0;
      opacity: 1;
    }
 
    100% {
      width: 102px;
      height: 102px;
      opacity: 0;
    }
  }
  @keyframes lds-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      width: 0;
      height: 0;
      opacity: 1;
    }
 
    100% {
      width: 102px;
      height: 102px;
      opacity: 0;
    }
  }
  
