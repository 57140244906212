.item-card {
  padding: 15px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.30);
}

.item-card-image-container {
  display: flex ;
  flex-direction: column;
}

.item-card img  {
  width: 140px;
  object-fit: cover;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}

.item-card .resever  {
  height: 100px;
  object-fit: cover;
  border-radius: 20px ;
}

.item-card p {
    font-family: "'GE Dinkum Medium'";
}

/*TamplateTwo  */
.item-card-2 {
  padding: 10px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.30);
}

.item-card-2-image-container {
  display: flex ;
  flex-direction: column;
}

.item-card-2 img  {
  max-width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  margin-bottom: 0%;
  margin-inline:auto;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}
.chosen-t2 img  {
  max-width: 80%;
  margin-top: 20%;
  border-radius: 50%;
}

.amount-label {
  visibility: hidden;
}
.chosen-t2 .amount-label {
  visibility: visible;
}
.price{
  transition: all 300ms ease-in-out;
}
.chosen-t2 .price {
  top: -30px;
}

/* font-family: "'BoutrosMBCDinkum Medium'"; */
.item-card-2 p {
    font-family: "'GE Dinkum Medium'";
}